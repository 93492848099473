<template>
  <div
    class="keyboard-tooltip-container"
    v-tooltip="{
      content: content,
      delay: {show: 500},
      html: true,
      autoHide: false,
      offset: 10,
    }"
  >
    <slot />
  </div>
</template>

<script>
export default {
  name: "KeyboardTooltip",
  props: {
    keyName: { type: String, default: null },
    label: { type: String, default: null },
    labelAfter: { type: String, default: null },
    keyboardIcon: { type: Boolean, default: false },
  },
  computed: {
    content() {
      const icon = this.keyboardIcon ? "<i class='v-icon material-icons'>keyboard</i>" : null
      const keyTip = this.keyName ? `<span class='keyboard-tip'>${this.keyName}</span>` : null


      return [
        this.label,
        icon,
        keyTip,
        this.labelAfter,
      ].filter(Boolean).join("&nbsp;&nbsp;")
    },
  },
}
</script>

<style scoped>
 .keyboard-tooltip-container {
   display: inline;
 }
</style>

<style >
  .keyboard-tip {
    padding: 0 4px 0px;
    margin: 0;
    border-radius: 4px;
    background: #ffffff35;
    color: #ffffff;
  }
</style>
